
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import DeptUserTree from './component/dept-user-tree.vue'
import Config from '@/config'

@Options({
  components: {
    PagePlugin,
    CustomDialog,
    DeptUserTree
  }
})
export default class my_patent extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  resultFormOpt = Config.resultForm
  tb = {
    page: 1,
    pageSize: 10
  }
  pageChange({ currentPage, pageSize }) {
    this.tb.page = currentPage
    this.tb.pageSize = pageSize
  }
  // 添加发明人-start
  users_dialog = {
    open: false,
    title: '邀请发明人',
    users: [] as any[],
    user_ids: [] as number[],
    is_edit: false // 是否为编辑窗
  }
  addPerson(is_edit: boolean) {
    if (is_edit) {
      this.users_dialog.users = this.t_edit_data.person_info.inventor
      this.users_dialog.user_ids = this.t_edit_data.person_info.inventor.map(
        (item) => item.id
      )
    } else {
      this.users_dialog.users = this.t_add_data.person_info.inventor
      this.users_dialog.user_ids = this.t_add_data.person_info.inventor.map(
        (item) => item.id
      )
    }
    this.users_dialog.is_edit = is_edit
    this.users_dialog.open = true
  }
  delPerson(is_edit: boolean, row: any) {
    // console.log(row)
    if (is_edit) {
      this.t_edit_data.person_info.inventor =
        this.t_edit_data.person_info.inventor.filter(
          (item) => item.id !== row.id
        )
      if (!this.t_edit_data.person_info.inventor.length) {
        this.t_edit_data.person_info.inventor = [] as any[]
      }
    } else {
      this.t_add_data.person_info.inventor =
        this.t_add_data.person_info.inventor.filter(
          (item) => item.id !== row.id
        )
      if (!this.t_add_data.person_info.inventor.length) {
        this.t_add_data.person_info.inventor = [] as any[]
      }
    }
  }
  users_dialog_cancle() {
    this.users_dialog.open = false
  }
  users_dialog_ok() {
    this.users_dialog.open = false
    if (this.users_dialog.is_edit) {
      this.t_edit_data.person_info.inventor = this.users_dialog.users
    } else {
      this.t_add_data.person_info.inventor = this.users_dialog.users
    }
  }
  // end

  validateRadio = (rule, value, callback) => {
    if (value) {
      if (value === 0) {
        callback(new Error('请填写完整'))
      } else {
        callback()
      }
    } else {
      callback(new Error('请填写完整'))
    }
  }

  t_all_data = [] as any[]

  t_total = 0
  t_search_data = {
    start_time: '',
    end_time: '',
    name: '',
    type: '',
    status: '',
    order_by: '',
    sort: ''
  }

  rule_t_search_data = {}

  t_add_dialog = {
    open: false,
    title: '添加专利'
  }

  t_add_data = {
    id: '',
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      dept: '',
      inventor: [
        // { name: '', id_number: '', dept: '', duty: '', phone: '' }
      ] as any[]
    },
    base_info: {
      patent_type: 2,
      name: '',
      number: '',
      purpose: [] as any[],
      authorization: 0,
      authorization_range: [],
      // type: 0,
      type: '' as string | number,
      industry_involved: [],
      related_industry: [],
      invention_member: '',
      patentee: '',
      result_type: 3 as string | number,
      result_form: '新发现',
      _result_form: '',
      has_others: 1 as string | number,
      others_msg: [{ name: '', num: '' }] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: [] as any[]
  }
  t_add_data_copy = {
    id: '',
    person_info: {
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      dept: '',
      inventor: [
        // { name: '', id_number: '', dept: '', duty: '', phone: '' }
      ] as any[]
    },
    base_info: {
      patent_type: 2,
      name: '',
      // type: 0,
      type: '' as string | number,
      number: '',
      purpose: [] as any[],
      authorization: 0,
      authorization_range: [],
      industry_involved: [],
      related_industry: [],
      invention_member: '',
      patentee: '',
      result_type: 3 as string | number,
      result_form: '新发现',
      _result_form: '',
      has_others: 1 as string | number,
      others_msg: [{ name: '', num: '' }] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: []
  }

  add_has_others_change(e) {
    if (e === 1) {
      if (this.t_add_data.base_info.others_msg.length === 0) {
        this.t_add_data.base_info.others_msg.push({ name: '', num: '' })
      }
    }
  }
  edit_has_others_change(e) {
    if (e === 1) {
      if (!Array.isArray(this.t_edit_data.base_info.others_msg)) {
        this.t_edit_data.base_info.others_msg = [{ name: '', num: '' }]
      }
      if (this.t_edit_data.base_info.others_msg.length === 0) {
        this.t_edit_data.base_info.others_msg.push({ name: '', num: '' })
      }
    }
  }

  rule_t_add_data = {
    'person_info.contact': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.phone': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: '请填写正确手机号',
        trigger: 'blur'
      }
    ],
    'person_info.email': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { type: 'email', message: '邮箱格式不正确', trigger: 'blur' }
    ],
    'person_info.address': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.duty': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.dept': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.inventor': [
      { required: true, message: '请选择发明人', trigger: 'blur' }
    ],
    'base_info.name': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.number': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.authorization': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.authorization_range': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.type': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.industry_involved': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.invention_member': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.patentee': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.result_type': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.result_form': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    // 'base_info.has_others': [
    //   { required: true, message: '请填写完整', trigger: 'blur' }
    // ],
    'base_info.digest': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.claim_rights': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.specification': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.significance': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.question': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.plan': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    // 'conversion_situation.max_price': [
    //   { required: true, message: '请填写完整', trigger: 'blur' },
    //   {
    //     pattern: /^[1-9]\d*(\.\d+)?$/,
    //     message: '填写数值必须大于0',
    //     trigger: 'blur'
    //   }
    // ],
    'conversion_situation.min_price': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      {
        pattern: /^[1-9]\d*(\.\d+)?$/,
        message: '填写数值必须大于0',
        trigger: 'blur'
      }
    ],
    'conversion_situation.expect': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.conditions': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit_select': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ]
    // 'self_info': [
    //   { required: true, message: '请填写完整3', trigger: 'blur' }
    // ]
  }

  t_detail_dialog = {
    open: false,
    title: '详情'
  }

  t_detail_data = {
    person_info: {
      dept: '', // 部门单位
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      number: '',
      purpose: '',
      authorization: 0,
      authorization_range: '',
      industry_involved: [],
      related_industry: [],
      invention_member: '',
      patentee: '',
      digest: '', // 摘要
      claim_rights_file: '' as any,
      digest_file: '' as any,
      specification_file: '' as any,
      claim_rights: '', // 权利要求书
      specification: '', // 说明书
      result_type: '' as string | number,
      result_form: '',
      _result_form: '',
      has_others: 1 as string | number,
      others_msg: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: [] as any[]
  }

  t_del_dialog = {
    open: false,
    title: '删除'
  }

  t_del_data = {
    id: ''
  }

  t_edit_dialog = {
    open: false,
    title: '编辑'
  }

  t_edit_data = {
    id: '',
    person_info: {
      dept: '',
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      name: '',
      type: 0,
      number: '',
      purpose: [] as any,
      authorization: 0,
      authorization_range: [],
      industry_involved: [],
      related_industry: [],
      invention_member: '',
      patentee: '',
      result_type: '' as string | number,
      result_form: '新发现',
      _result_form: '',
      has_others: 1 as string | number,
      others_msg: [] as any,
      digest: '', // 摘要
      digest_file: [] as any,
      claim_rights: '', // 权利要求书
      claim_rights_file: [] as any,
      specification: '', // 说明书
      specification_file: [] as any
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: [] as any,
      max_price: 0,
      min_price: 0,
      permit: '',
      permit_select: '',
      permit_amount: 0
    },
    self_info: [] as any[]
  }
  rule_t_edit_data = {
    'person_info.contact': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.phone': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      {
        pattern: /^1[3456789]\d{9}$/,
        message: '请填写正确手机号',
        trigger: 'blur'
      }
    ],
    'person_info.email': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { type: 'email', message: '邮箱格式不正确', trigger: 'blur' }
    ],
    'person_info.address': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.duty': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.dept': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'person_info.inventor': [
      { required: true, message: '请选择发明人', trigger: 'blur' }
    ],
    'base_info.name': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.number': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.authorization': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.authorization_range': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.type': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { validator: this.validateRadio, trigger: 'blur' }
    ],
    'base_info.industry_involved': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.invention_member': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.patentee': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.result_type': [
      { required: true, message: '请填写完整', trigger: 'blur' },
      { validator: this.validateRadio, trigger: 'blur' }
    ],
    'base_info.result_form': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    // 'base_info.has_others': [
    //   { required: true, message: '请填写完整', trigger: 'blur' },
    //   { validator: this.validateRadio, trigger: 'blur' }
    // ],
    'base_info.digest': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.claim_rights': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'base_info.specification': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.significance': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.question': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'supplement.plan': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    // 'conversion_situation.max_price': [
    //   {
    //     type: 'number',
    //     required: true,
    //     message: '请填写完整',
    //     trigger: 'blur'
    //   },
    //   {
    //     pattern: /^[1-9]\d*(\.\d+)?$/,
    //     message: '填写数值必须大于0',
    //     trigger: 'blur'
    //   }
    // ],
    'conversion_situation.min_price': [
      {
        type: 'number',
        required: true,
        message: '请填写完整',
        trigger: 'blur'
      },
      {
        pattern: /^[1-9]\d*(\.\d+)?$/,
        message: '填写数值必须大于0',
        trigger: 'blur'
      }
    ],
    'conversion_situation.expect': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.conditions': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ],
    'conversion_situation.permit_select': [
      { required: true, message: '请填写完整', trigger: 'blur' }
    ]
    // 'self_info': [
    //   { required: true, message: '请填写完整', trigger: 'blur' }
    // ]
  }

  t_edit1_dialog = {
    open: false,
    title: '提交'
  }
  t_add1_dialog = {
    open: false,
    title: '提交'
  }

  t_add1_init_postData = {} as any

  t_edit1_data = {
    id: '',
    evaluate: 1,
    open: 0
  }
  t_add1_data = {
    id: ''
  }

  rule_t_edit1_data = {}

  rule_t_add1_data = {}

  getIndustry: any[] = []
  getIndustry2: any[] = []
  WeightList: any[] = []
  type_654 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  status_657 = new Map<any, string>([
    [0, '草稿'],
    [1, '待成员确认'],
    [2, '待指派组长'],
    [3, '待分配专家'],
    [4, '待专家评'],
    [5, '待组长审核'],
    [6, '审核通过'],
    [7, '审核不通过'],
    [8, '待评估机构确认'],
    [9, '成员退回'],
    [10, '评估机构退回'],
    [11, '待报告审核'],
    [12, '报告退回']
  ])
  type_676 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  type_686 = new Map<any, string>([
    [1, '基础研究'],
    [2, '应用研究'],
    [3, '技术开发与产业化']
  ])
  type_696 = new Map<any, string>([
    [1, '否'],
    [2, '是']
  ])

  t_get_all_data(offset = 0, length = 10) {
    const postData = {
      offset: offset || 0,
      length: length || 10,
      start_time: this.t_search_data.start_time,
      end_time: this.t_search_data.end_time,
      name: this.t_search_data.name,
      type: this.t_search_data.type,
      status: this.t_search_data.status,
      patent_type: 2,
      order_by: this.t_search_data.order_by,
      sort: this.t_search_data.sort
    }
    Api.http_my_patentt0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_all_data = res.data.data
        // this.t_all_data[0].return_reason = '66666666666666666666666666666666666<br />9999999999999999999999999999999'
        this.t_total = res.data.total
      }
    })
  }

  t_add_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    this.t_add_dialog.open = true
    this.setDialogName(this.t_add_dialog.title)
  }

  t_add_cancle() {
    this.t_add_dialog.open = false
    this.t_add_data = this.t_add_data_copy
    this.removeDialogName()
  }

  t_add_ok() {
    // this.$refs.ref_t_add_data.validate((valid) => {
    //   if (valid) {
    const t_add_data = JSON.parse(JSON.stringify(this.t_add_data))

    const postData = {
      id: t_add_data.id,
      person_info: JSON.parse(JSON.stringify(t_add_data.person_info)),
      base_info: JSON.parse(JSON.stringify(t_add_data.base_info)),
      supplement: JSON.parse(JSON.stringify(t_add_data.supplement)),
      conversion_situation: JSON.parse(
        JSON.stringify(t_add_data.conversion_situation)
      ),
      self_info: JSON.parse(JSON.stringify(t_add_data.self_info))
    }
    postData.base_info.purpose = postData.base_info.purpose.join(',')
    postData.conversion_situation.expect = JSON.stringify(
      postData.conversion_situation.expect
    )
    postData.base_info.digest_file = JSON.stringify(
      postData.base_info.digest_file
    )
    if (
      this.resultFormOpt.find(
        (item) => item === postData.base_info.result_form
      ) === '其他'
    ) {
      postData.base_info.result_form = postData.base_info._result_form
    }
    if (postData.base_info.has_others) {
      postData.base_info.others_msg = JSON.stringify(
        postData.base_info.others_msg
      )
    } else {
      postData.base_info.others_msg = JSON.stringify([])
    }
    postData.base_info.claim_rights_file = JSON.stringify(
      postData.base_info.claim_rights_file
    )
    postData.base_info.specification_file = JSON.stringify(
      postData.base_info.specification_file
    )
    // console.log('postData :>> ', postData);
    // return
    // eslint-disable-next-line no-unreachable
    Api.http_my_patentt1(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_add_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
        this.t_add_data = this.t_add_data_copy
      }
    })
    //   }
    // })
  }

  t_del_init(row) {
    this.t_del_data.id = row.id
    this.t_del_dialog.open = true
  }
  t_del_cancle() {
    this.t_del_dialog.open = false
  }

  t_del_ok() {
    const postData = {
      id: this.t_del_data.id
    }
    Api.http_my_patentt2(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_del_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  t_detail_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    Api.http_my_patenttget4({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_detail_dialog.open = true
        // console.log('.....................', res.data);
        this.t_detail_data.person_info = res.data.person_info
        this.t_detail_data.base_info = res.data.base_info
        this.t_detail_data.supplement = res.data.supplement
        this.t_detail_data.conversion_situation = res.data.conversion_situation
        this.t_detail_data.self_info = res.data.self_info
        this.setDialogName(this.t_detail_dialog.title)
        if (Utils.isJSON(this.t_detail_data.base_info.others_msg)) {
          this.t_detail_data.base_info.others_msg = JSON.parse(
            this.t_detail_data.base_info.others_msg
          )
        }
        if (Utils.isJSON(this.t_detail_data.conversion_situation.expect)) {
          this.t_detail_data.conversion_situation.expect = JSON.parse(
            this.t_detail_data.conversion_situation.expect
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.claim_rights_file)) {
          this.t_detail_data.base_info.claim_rights_file = JSON.parse(
            this.t_detail_data.base_info.claim_rights_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.digest_file)) {
          this.t_detail_data.base_info.digest_file = JSON.parse(
            this.t_detail_data.base_info.digest_file
          )
        }
        if (Utils.isJSON(this.t_detail_data.base_info.specification_file)) {
          this.t_detail_data.base_info.specification_file = JSON.parse(
            this.t_detail_data.base_info.specification_file
          )
        }
        if (Array.isArray(this.t_detail_data.base_info.authorization_range)) {
          this.t_detail_data.base_info.authorization_range =
            this.t_detail_data.base_info.authorization_range.join('至')
        }
      }
    })
  }

  t_detail_cancle() {
    this.t_detail_dialog.open = false
    this.removeDialogName()
  }

  t_detail_ok() {
    this.t_detail_dialog.open = false
  }

  t_edit_init(row) {
    this.get_getIndustry(row.id)
    this.get_getIndustry2(row.id)
    this.get_WeightList(row.id)
    Api.http_my_patenttget5({ id: row.id }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_data.id = row.id
        this.t_edit_data.person_info = res.data.person_info
        this.t_edit_data.base_info = res.data.base_info
        if (
          !this.resultFormOpt.find(
            (item) => item === this.t_edit_data.base_info.result_form
          )
        ) {
          this.t_edit_data.base_info._result_form =
            this.t_edit_data.base_info.result_form
          this.t_edit_data.base_info.result_form = '其他'
        }
        this.t_edit_data.supplement = res.data.supplement
        this.t_edit_data.conversion_situation = res.data.conversion_situation
        this.t_edit_data.self_info = res.data.self_info
        this.t_edit_dialog.open = true
        const expect = JSON.parse(this.t_edit_data.conversion_situation.expect)
        this.t_edit_data.conversion_situation.expect =
          typeof expect === 'object' ? expect : []
        this.t_edit_data.base_info.digest_file = Utils.isJSON(
          this.t_edit_data.base_info.digest_file
        )
          ? JSON.parse(this.t_edit_data.base_info.digest_file)
          : this.t_edit_data.base_info.digest_file
        this.t_edit_data.base_info.claim_rights_file = Utils.isJSON(
          this.t_edit_data.base_info.claim_rights_file
        )
          ? JSON.parse(this.t_edit_data.base_info.claim_rights_file)
          : this.t_edit_data.base_info.claim_rights_file
        this.t_edit_data.base_info.specification_file = Utils.isJSON(
          this.t_edit_data.base_info.specification_file
        )
          ? JSON.parse(this.t_edit_data.base_info.specification_file)
          : this.t_edit_data.base_info.specification_file
        this.setDialogName(this.t_edit_dialog.title)
        this.t_edit_data.base_info.others_msg = Utils.isJSON(
          this.t_edit_data.base_info.others_msg
        )
          ? JSON.parse(this.t_edit_data.base_info.others_msg)
          : ''
        this.t_edit_data.base_info.purpose = this.t_edit_data.base_info.purpose
          ? this.t_edit_data.base_info.purpose.split(',')
          : []
      }
    })
  }

  t_edit_cancle() {
    this.t_edit_dialog.open = false
    this.removeDialogName()
  }

  t_edit_ok() {
    // this.$refs.ref_t_edit_data.validate((valid) => {
    //   if (valid) {
    const t_edit_data = JSON.parse(JSON.stringify(this.t_edit_data))

    const postData = {
      id: t_edit_data.id,
      person_info: JSON.parse(JSON.stringify(t_edit_data.person_info)),
      base_info: JSON.parse(JSON.stringify(t_edit_data.base_info)),
      supplement: JSON.parse(JSON.stringify(t_edit_data.supplement)),
      conversion_situation: JSON.parse(
        JSON.stringify(t_edit_data.conversion_situation)
      ),
      self_info: JSON.parse(JSON.stringify(t_edit_data.self_info))
    }
    postData.base_info.purpose = postData.base_info.purpose.join(',')
    postData.conversion_situation.expect = JSON.stringify(
      postData.conversion_situation.expect
    )
    postData.base_info.digest_file = JSON.stringify(
      postData.base_info.digest_file
    )
    postData.base_info.claim_rights_file = JSON.stringify(
      postData.base_info.claim_rights_file
    )
    if (
      this.resultFormOpt.find(
        (item) => item === postData.base_info.result_form
      ) === '其他'
    ) {
      postData.base_info.result_form = postData.base_info._result_form
    }
    if (postData.base_info.has_others) {
      postData.base_info.others_msg = JSON.stringify(
        postData.base_info.others_msg
      )
    } else {
      postData.base_info.others_msg = JSON.stringify([])
    }
    postData.base_info.specification_file = JSON.stringify(
      postData.base_info.specification_file
    )
    //
    // if (this.t_edit_data.self_info.length === 0) {
    //   this.$message.error('请完善自评信息');
    //   return
    // }
    // let flag = -1
    // this.t_edit_data.self_info.forEach((item, index) => {
    //   if (item.weight > 0) {
    //     if (item.score === null || item.description === '') {
    //       flag += 1
    //     }
    //   }
    // })
    // if (flag > -1) {
    //   this.$message.error('请完善自评信息');
    //   return
    // }
    Api.http_my_patentt5(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.t_edit_dialog.open = false
        this.removeDialogName()
        this.t_get_all_data(
          (this.tb.page - 1) * this.tb.pageSize,
          this.tb.pageSize
        )
        this.$message.success(JSON.stringify(res.message))
      }
    })
    //   }
    // })
  }

  t_edit1_init() {
    // console.log('this.t_edit_data.self_info', JSON.parse(JSON.stringify(this.t_edit_data.self_info)))
    this.$refs.ref_t_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.t_edit_data.id,
          person_info: JSON.parse(JSON.stringify(this.t_edit_data.person_info)),
          base_info: JSON.parse(JSON.stringify(this.t_edit_data.base_info)),
          supplement: JSON.parse(JSON.stringify(this.t_edit_data.supplement)),
          conversion_situation: JSON.parse(
            JSON.stringify(this.t_edit_data.conversion_situation)
          ),
          self_info: JSON.parse(JSON.stringify(this.t_edit_data.self_info))
        }
        postData.base_info.purpose = postData.base_info.purpose.join(',')
        postData.conversion_situation.expect = JSON.stringify(
          postData.conversion_situation.expect
        )
        postData.base_info.digest_file = JSON.stringify(
          postData.base_info.digest_file
        )
        postData.base_info.claim_rights_file = JSON.stringify(
          postData.base_info.claim_rights_file
        )
        if (postData.base_info.has_others) {
          postData.base_info.others_msg = JSON.stringify(
            postData.base_info.others_msg
          )
        } else {
          postData.base_info.others_msg = JSON.stringify([])
        }
        if (
          this.resultFormOpt.find(
            (item) => item === postData.base_info.result_form
          ) === '其他'
        ) {
          postData.base_info.result_form = postData.base_info._result_form
        }
        postData.base_info.specification_file = JSON.stringify(
          postData.base_info.specification_file
        )
        //
        if (this.t_edit_data.self_info.length === 0) {
          return this.$message.error('请完善自评信息')
        }
        let flag = -1
        this.t_edit_data.self_info.forEach((item, index) => {
          if (item.weight > 0) {
            if (item.score === null) {
              flag += 1
            }
          }
        })
        if (flag > -1) return this.$message.error('请完善自评信息')
        Api.http_my_patentt5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit1_dialog.open = true
            this.t_edit1_data.id = res.data
            Api.http_my_patenttget6({ id: this.t_edit_data.id }).then(
              (res1) => {
                if (res1.code !== '200') {
                  this.$message.error(res1.message)
                } else {
                  this.t_edit1_data.id = this.t_edit_data.id
                  this.t_edit1_dialog.open = true
                }
              }
            )
          }
        })
      }
    })
  }
  t_add1_init() {
    this.$refs.ref_t_add_data.validate((valid) => {
      const t_add_data = JSON.parse(JSON.stringify(this.t_add_data))
      if (valid) {
        const postData = {
          id: t_add_data.id,
          person_info: JSON.parse(JSON.stringify(t_add_data.person_info)),
          base_info: JSON.parse(JSON.stringify(t_add_data.base_info)),
          supplement: JSON.parse(JSON.stringify(t_add_data.supplement)),
          conversion_situation: JSON.parse(
            JSON.stringify(t_add_data.conversion_situation)
          ),
          self_info: JSON.parse(JSON.stringify(t_add_data.self_info))
        }
        postData.base_info.purpose = postData.base_info.purpose.join(',')
        postData.conversion_situation.expect = JSON.stringify(
          postData.conversion_situation.expect
        )
        postData.base_info.digest_file = JSON.stringify(
          postData.base_info.digest_file
        )
        if (postData.base_info.has_others) {
          postData.base_info.others_msg = JSON.stringify(
            postData.base_info.others_msg
          )
        } else {
          postData.base_info.others_msg = JSON.stringify([])
        }
        postData.base_info.claim_rights_file = JSON.stringify(
          postData.base_info.claim_rights_file
        )
        postData.base_info.specification_file = JSON.stringify(
          postData.base_info.specification_file
        )
        if (
          this.resultFormOpt.find(
            (item) => item === postData.base_info.result_form
          ) === '其他'
        ) {
          postData.base_info.result_form = postData.base_info._result_form
        }
        if (t_add_data.self_info.length === 0) {
          return this.$message.error('请完善自评信息')
        }

        let flag = -1
        t_add_data.self_info.forEach((item, index) => {
          if (item.weight > 0) {
            if (item.score === null) {
              flag += 1
            }
          }
        })
        if (flag > -1) return this.$message.error('请完善自评信息')
        // 打开确认框
        this.t_add1_init_postData = postData
        this.t_add1_dialog.open = true
      }
    })
  }

  t_edit1_cancle() {
    this.t_edit1_dialog.open = false
  }
  t_add1_cancle() {
    this.t_add1_dialog.open = false //
  }

  t_edit1_ok() {
    this.t_edit1_dialog.open = false
    this.$refs.ref_t_edit1_data.validate((valid) => {
      if (valid) {
        const postData = { ...this.t_edit1_data }
        Api.http_my_patentt6(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.t_edit_dialog.open = false
            this.t_get_all_data(
              (this.tb.page - 1) * this.tb.pageSize,
              this.tb.pageSize
            )
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  t_add1_ok() {
    this.$refs.ref_t_add1_data.validate((valid) => {
      if (valid) {
        Api.http_my_patentt1(this.t_add1_init_postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            // console.log('aaaaaaaaaaa', res)
            this.t_add1_data.id = res.data.id // 传值
            this.t_add1_init_postData = {} as any
            // this.removeDialogName()
            // this.t_get_all_data(0, 10)
            // this.$message.success(JSON.stringify(res.message))
            const postData = { id: this.t_add1_data.id }
            Api.http_my_patentt6(postData).then((res1) => {
              if (res1.code !== '200') {
                this.$message.error(res1.message)
              } else {
                this.t_add1_dialog.open = false
                this.t_add_dialog.open = false
                this.t_get_all_data(
                  (this.tb.page - 1) * this.tb.pageSize,
                  this.tb.pageSize
                )
                this.$message.success(JSON.stringify(res1.message))
                this.t_add_data = this.t_add_data_copy
              }
            })
          }
        })
      }
    })
  }

  t_init() {
    this.t_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }
  get_getIndustry(id = -1) {
    Api.http_my_patenttgetIndustry0({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') {
          this.$message.error(res.message)
        } else {
          this.getIndustry = res.data.data
        }
      }
    )
  }
  get_getIndustry2(id = -1) {
    Api.http_my_patenttgetIndustry20({ id: id, offset: 0, length: 999 }).then(
      (res) => {
        if (res.code !== '200') this.$message.error(res.message)
        else this.getIndustry2 = res.data.data
      }
    )
  }
  get_WeightList(id = -1) {
    Api.http_my_patenttWeightList0({
      id: id,
      offset: 0,
      length: 999,
      type_id: 6
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        // console.log('55555555555', res)
        this.WeightList = res.data.data
      }
    })
  }
  created() {
    this.t_init()
  }
}
