
import { Options } from 'vue-class-component'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import { ref } from 'vue'
import Utils from '@/utils'

@Options({
  components: {
    PagePlugin
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return []
      }
    },
    userList: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    deptList: {
      type: Array,
      default() {
        return []
      }
    }
  }
})
export default class user extends SuperVue {
  // props
  modelValue!: number[]
  userList!: any[]
  deptList!: any[]
  // data
  dialog_a = {
    open: false
  }
  tree_data = ref([])
  // method

  created() {
    this.getAllUserList()
    this.getDeptTree()
  }

  userRows={
    rows: [] as any[],
    search: {
      keyword: '',
      dept_id: []
    }
  }

  getAllUserList() {
    const postData = { keyword: '', id: '' }
    postData.keyword = this.userRows.search.keyword
    if (this.userRows.search.dept_id.length) postData.id = this.userRows.search.dept_id[this.userRows.search.dept_id.length - 1]
    Api.http_deptGetUserList(postData).then((res) => {
      if (res.code === '200') {
        this.userRows.rows = res.data
        this.userRows.rows.forEach(item => {
          item.secretPhone = this.getPhoneEnd(item.phone)
          item.selected = this.userList.findIndex((ele) => ele.id === item.id) !== -1
        })

      }
    })
  }

  selectTableUser(row) {
    if (row.selected) {
      this.modelValue.push(row.id)
      this.userList.push(row)
    } else {
      let idx = this.modelValue.indexOf(row.id)
      if (idx !== -1) this.modelValue.splice(idx, 1)
      idx = this.userList.findIndex((item) => item.id === row.id)
      if (idx !== -1) this.userList.splice(idx, 1)
    }
  }

  getPhoneEnd(phone) {
    const phoneString = phone.toString()
    return '*******' + phoneString.substring(phoneString.length - 4)
  }

  delUserId(index: number): void {
    const id = this.userList[index].id
    this.userList.splice(index, 1)
    const idx = this.modelValue.indexOf(id)
    if (idx !== -1) this.modelValue.splice(idx, 1)
    const rowIndex = this.userRows.rows.findIndex(item => item.id === id)
    if (rowIndex !== -1) this.userRows.rows[rowIndex].selected = false
  }

  getDeptTree() {
    Api.http_deptdepts0({ offset: 0, length: 999 }).then((res) => {
      if (res.code === '200') {
        res.data.data.forEach((element) => {
          // element.treeId = element.id
          element.label = element.name
          element.value = element.id
        })
        const tree = Utils.list2tree(res.data.data, 'id', 'parent_id', 'children')
        this.tree_data = tree
      }
    })
  }

  // created() {
  //   if (!this.deptList.length) {
  //     Api.http_deptdepts0({ offset: 0, length: 999 }).then((res) => {
  //       if (res.code === '200') {
  //         res.data.data.forEach((element) => {
  //           element.treeId = element.id
  //           element.label = element.name
  //         })
  //         const tree = Utils.list2tree2(res.data.data, 'id', 'parent_id', 'children')
  //         this.tree_data = tree
  //       }
  //     })
  //   } else {
  //     this.deptList.forEach(item => {
  //       item.treeId = item.id
  //       item.label = item.name
  //     })
  //     const tree = Utils.list2tree2(this.deptList, 'id', 'parent_id', 'children')
  //     this.tree_data = tree
  //   }
  // }

  // treeOpen(data: any, node: any, curVM: any) {
  //   if (data.id < 0) return
  //   if (Array.isArray(data.children[0].children)) {
  //     const len = data.children[0].children.length
  //     if (len === 0) {
  //       Api.http_deptGetUserList({ id: data.id }).then((res) => {
  //         if (res.code === '200') {
  //           res.data.forEach((element) => {
  //             element.treeId = 'user_' + element.id
  //             element.label = element.name
  //             element.checkBox = true
  //             element.value = this.modelValue.includes(element.id)
  //           })
  //           data.children[0].children = res.data
  //         }
  //       })
  //     }
  //   }
  // }
  // select_user(e: boolean, data: any, node: any) {
  //   if (e) {
  //     this.modelValue.push(data.id)
  //     this.userList.push(data)
  //   } else {
  //     let idx = this.modelValue.indexOf(data.id)
  //     if (idx !== -1) this.modelValue.splice(idx, 1)
  //     idx = this.userList.findIndex((item) => item.id === data.id)
  //     if (idx !== -1) this.userList.splice(idx, 1)
  //   }
  // }
  // delUserId(index: number): void {
  //   const id = this.userList[index].id
  //   this.userList.splice(index, 1)
  //   const idx = this.modelValue.indexOf(id)
  //   if (idx !== -1) this.modelValue.splice(idx, 1)
  //   this.$refs.tree.getNode('user_' + id).data.value = false
  // }
}
